import * as React from 'react'
import { AppContainer } from '/components/AppContainer'
import {
  LoginPage,
  CategoriesPage,
  ProductsPage,
  OrdersPage,
  DeliverablesPage,
  DeliveriesPage,
  CustomersPage,
  UsersPage,
  LogsPage,
  DeliveryRulePage,
  TemplatesPage,
  EventsPage,
  StoreOrdersPage,
  HypeTransactionsPage,
  TodoIncommTransactionsPage,
} from '/pages'
import { sessionStore } from '/stores/SessionStore'
import { Router, Route, Redirect, Switch } from 'react-router-dom'
import createBrowserHistory from 'history/createBrowserHistory'
import { userService } from '/services/user'
import { client } from '/utils/http-client'
import * as LoginStatus from './LoginStatus'
import { Centered } from '/components/Misc'
import { Spin, Alert, Typography } from 'antd'
import { sleep } from '/utils/promises'
import './Root.less'

const { Text } = Typography
const history = createBrowserHistory()

export class App extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loginStatus: LoginStatus.NotChecked,
      user: null,
    }
  }

  async componentDidMount() {
    const token = sessionStore.getToken()
    const sleepPromise = sleep(750)

    if (!token) {
      await sleepPromise
      this.setState({
        loginStatus: LoginStatus.NotLoggedIn,
      })
      return
    } else {
      const { code } = await userService.getUser()

      if (code == 'InvalidToken') {
        sessionStore.clear()
        location.reload()
      }
    }

    const userPromise = userService.getUser()
    await sleepPromise

    this.setState({
      user: await userPromise,
      loginStatus: LoginStatus.LoggedIn,
    })
  }

  onLoginSuccess = async data => {
    sessionStore.setToken(data.token)
    const user = await userService.getUser()
    this.setState({
      user,
      loginStatus: LoginStatus.LoggedIn,
    })
  }

  onLogoutSuccess = () => {
    sessionStore.clear()
    this.setState({
      user: null,
      loginStatus: LoginStatus.NotLoggedIn,
    })
  }

  render() {
    if (this.state.loginStatus === LoginStatus.NotChecked) {
      return (
        <Centered>
          <Spin tip='Carregando...' />
        </Centered>
      )
    }

    if (this.state.loginStatus === LoginStatus.NotLoggedIn) {
      return <LoginPage loginSuccess={this.onLoginSuccess} />
    }

    return (
      <Router history={history}>
        <div className='root'>
          <Switch>
            <Redirect from='/' to='/deliverables' exact />
            <Route
              path='/categories'
              render={props => {
                return (
                  <AppContainer
                    {...this.props}
                    history={history}
                    user={this.state.user}
                    logoutSuccess={this.onLogoutSuccess}
                    match={props.match}
                  >
                    <CategoriesPage {...props} />
                  </AppContainer>
                )
              }}
            />
            <Route
              path='/products'
              render={props => {
                return (
                  <AppContainer
                    user={this.state.user}
                    history={history}
                    logoutSuccess={this.onLogoutSuccess}
                    match={props.match}
                  >
                    <ProductsPage {...props} />
                  </AppContainer>
                )
              }}
            />
            <Route
              path='/store-orders'
              render={props => {
                return (
                  <AppContainer
                    user={this.state.user}
                    history={history}
                    logoutSuccess={this.onLogoutSuccess}
                    match={props.match}
                  >
                    <StoreOrdersPage {...props} />
                  </AppContainer>
                )
              }}
            />
            <Route
              path='/orders'
              render={props => {
                return (
                  <AppContainer
                    user={this.state.user}
                    history={history}
                    logoutSuccess={this.onLogoutSuccess}
                    match={props.match}
                  >
                    <OrdersPage {...props} />
                  </AppContainer>
                )
              }}
            />
            <Route
              path='/delivery-rules'
              render={props => {
                return (
                  <AppContainer
                    user={this.state.user}
                    history={history}
                    logoutSuccess={this.onLogoutSuccess}
                    match={props.match}
                  >
                    <DeliveryRulePage {...props} />
                  </AppContainer>
                )
              }}
            />
            <Route
              path='/deliverables'
              render={props => {
                return (
                  <AppContainer
                    user={this.state.user}
                    history={history}
                    logoutSuccess={this.onLogoutSuccess}
                    match={props.match}
                  >
                    <DeliverablesPage {...props} />
                  </AppContainer>
                )
              }}
            />
            <Route
              path='/hype'
              render={props => {
                return (
                  <AppContainer
                    user={this.state.user}
                    history={history}
                    logoutSuccess={this.onLogoutSuccess}
                    match={props.match}
                  >
                    {/* <h2>Teste</h2> */}
                    <HypeTransactionsPage {...props} />
                  </AppContainer>
                )
              }}
            />
            <Route
              path='/todo-incomm'
              render={props => {
                return (
                  <AppContainer
                    user={this.state.user}
                    history={history}
                    logoutSuccess={this.onLogoutSuccess}
                    match={props.match}
                  >
                    <TodoIncommTransactionsPage {...props} />
                  </AppContainer>
                )
              }}
            />
            <Route
              path='/customers'
              render={props => {
                return (
                  <AppContainer
                    user={this.state.user}
                    history={history}
                    logoutSuccess={this.onLogoutSuccess}
                    match={props.match}
                  >
                    <CustomersPage {...props} />
                  </AppContainer>
                )
              }}
            />
            <Route
              path='/users'
              render={props => {
                return (
                  <AppContainer
                    user={this.state.user}
                    history={history}
                    logoutSuccess={this.onLogoutSuccess}
                    match={props.match}
                  >
                    <UsersPage {...props} />
                  </AppContainer>
                )
              }}
            />
            <Route
              path='/deliveries'
              render={props => {
                return (
                  <AppContainer
                    user={this.state.user}
                    history={history}
                    logoutSuccess={this.onLogoutSuccess}
                    match={props.match}
                  >
                    <DeliveriesPage {...props} />
                  </AppContainer>
                )
              }}
            />
            <Route
              path='/templates'
              render={props => {
                return (
                  <AppContainer
                    user={this.state.user}
                    history={history}
                    logoutSuccess={this.onLogoutSuccess}
                    match={props.match}
                  >
                    <TemplatesPage {...props} />
                  </AppContainer>
                )
              }}
            />
            <Route
              path='/events'
              render={props => {
                return (
                  <AppContainer
                    user={this.state.user}
                    history={history}
                    logoutSuccess={this.onLogoutSuccess}
                    match={props.match}
                  >
                    <EventsPage {...props} />
                  </AppContainer>
                )
              }}
            />
            <Route
              path='/logs'
              render={props => {
                return (
                  <AppContainer
                    user={this.state.user}
                    history={history}
                    logoutSuccess={this.onLogoutSuccess}
                    match={props.match}
                  >
                    <LogsPage {...props} />
                  </AppContainer>
                )
              }}
            />
            <Route
              render={props => {
                return (
                  <AppContainer
                    user={this.state.user}
                    history={history}
                    logoutSuccess={this.onLogoutSuccess}
                    match={props.match}
                  >
                    <Text>Página não encontrada.</Text>
                  </AppContainer>
                )
              }}
            />
          </Switch>
        </div>
      </Router>
    )
  }
}
