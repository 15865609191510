import React, { useState, useEffect } from 'react'

import { Tabs } from 'antd'

import { TodoIncommCreate, TodoIncommReport } from '/components/TodoIncomm'

const { TabPane } = Tabs

export function TodoIncommTransactionsPage() {
  const [tab, setTab] = useState('1')

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const tabParams = params.get('tab')

    setTab(tabParams ?? '1')
  }, [])

  const handleChangeTab = tabKey => {
    setTab(tabKey)
  }

  return (
    <Tabs activeKey={tab} onChange={tabKey => handleChangeTab(tabKey)}>
      <TabPane tab='Relatório de ativações' key='1'>
        <TodoIncommReport />
      </TabPane>

      <TabPane tab='Ativar cartões' key='2'>
        <TodoIncommCreate />
      </TabPane>
    </Tabs>
  )
}
