export function download(filename, content, type = 'text/plain') {
  const element = document.createElement('a')
  element.setAttribute(
    'href',
    `data:${type};charset=utf-8,` + encodeURIComponent(content),
  )
  element.setAttribute('download', filename)

  element.style.display = 'none'
  document.body.appendChild(element)

  element.click()

  document.body.removeChild(element)
}
