import { client } from '/utils/http-client'
import { sessionStore } from '/stores/SessionStore'

export const todoIncommService = {
  async startBulkActivation({ timer, items }) {
    const response = await client.post(
      `/todo-incomm/bulk-activate`,
      { timer, items },
      {
        headers: { 'x-access-token': sessionStore.getToken() },
      },
    )

    return response
  },

  async getTransactionsReport({ startDate, endDate }) {
    const query = [
      ...(startDate ? [`startDate=${startDate}`] : []),
      ...(endDate ? [`endDate=${endDate}`] : []),
    ].join('&')

    const response = await client.get(`/todo-incomm/transactions?${query}`, {
      headers: { 'x-access-token': sessionStore.getToken() },
    })

    return response
  },
}
