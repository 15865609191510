import React, { useState } from 'react'

import { message, Card, Button, Row, Col, Typography, DatePicker } from 'antd'
import { format } from 'date-fns'
import Papa from 'papaparse'

import { todoIncommService } from '/services'
import { download } from '/utils/download'

const { Text } = Typography

export function TodoIncommReport() {
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')

  const handleChangeDate = (type, value) => {
    const initialDate = new Date(value)
    initialDate.setHours(0, 0, 0, 0)
    const date = format(initialDate, 'yyyy-MM-dd')

    if (date === '1969-12-31') {
      if (type === 'start') setStartDate('')
      if (type === 'end') setEndDate('')
    } else {
      if (type === 'start') setStartDate(date)
      if (type === 'end') setEndDate(date)
    }
  }

  const handleSubmit = async () => {
    try {
      if (!startDate && !endDate) {
        message.warning(
          'Para gerar o relatório selecione a data inicial ou final.',
          5,
        )
        return
      }

      const { data } = await todoIncommService.getTransactionsReport({
        startDate,
        endDate,
      })

      const csvPayload = data.map(row => ({
        ...row,
        pre_activation_response: row.pre_activation_response
          ? JSON.stringify(row.pre_activation_response)
          : null,
        activation_response: row.activation_response
          ? JSON.stringify(row.activation_response)
          : null,
        cancel_response: row.cancel_response
          ? JSON.stringify(row.cancel_response)
          : null,
      }))

      if (data.length > 0) {
        const content = Papa.unparse(csvPayload, {
          header: true,
        })

        const fileNamePeriod = [
          ...(startDate ? [startDate] : []),
          ...(endDate ? [endDate] : []),
        ].join('--')

        download(
          `todo-incomm-report-${fileNamePeriod}.csv`,
          content,
          'csv/plain',
        )

        message.success('Relatório gerado com sucesso!', 5)
      } else {
        message.warning(
          'Não foi encontrado transações para o período informado.',
          5,
        )
      }
    } catch (exception) {
      message.error('Ocorreu um erro ao gerar o relatório.', 5)
      console.error({ exception })
    }
  }

  return (
    <Card bordered={false}>
      <Row
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          flexDirection: 'row',
          gap: '10px',
        }}
      >
        <Col>
          <Text style={{ fontWeight: 500 }}>Data Inicial</Text>

          <Row>
            <DatePicker
              style={{ width: 300 }}
              placeholder='DD/MM/YYYY'
              format='DD/MM/YYYY'
              onChange={value => handleChangeDate('start', value)}
            />
          </Row>
        </Col>

        <Col>
          <Text style={{ fontWeight: 500 }}>Data Final</Text>

          <Row>
            <DatePicker
              style={{ width: 300 }}
              placeholder='DD/MM/YYYY'
              format='DD/MM/YYYY'
              onChange={value => handleChangeDate('end', value)}
            />
          </Row>
        </Col>

        <Col style={{ marginTop: '20px' }}>
          <Button type='primary' onClick={() => handleSubmit()}>
            Gerar Relatório
          </Button>
        </Col>
      </Row>
    </Card>
  )
}
