import React, { useState } from 'react'

import { Button, Input, Form, Card, message } from 'antd'
import Papa from 'papaparse'
import * as Yup from 'yup'

import { todoIncommService } from '/services'
import { download } from '/utils/download'

const { Item } = Form

const MESSAGES_FEEDBACK = {
  INVALID_CSV:
    'Arquivo CSV inválido, por favor, revise se todos campos foram preenchidos.',
  INVALID_TIMER: 'Digite um valor entre 1 e 60.',
  TIMER_MUST_BE_GREATER_THAN: 'Digite um valor maior ou igual a 1.',
  TIMER_MUST_BE_LESS_THAN: 'Digite um valor menor ou igual a 60.',
  REQUIRED_FIELDS:
    'Para prosseguir, é necessário preencher todos os campos marcados como obrigatório.',
  API_ERROR:
    'Houve um erro ao enviar a requisição para a API, por favor tente novamente.',
  ACTIVATION_SUCCESSFULLY: 'Ativação iniciada com sucesso!',
  ACTIVATION_FAILED:
    'Houve um erro ao tentar iniciar a ativação, por favor tente novamente.',
  INVALID_UPLOAD_CSV:
    'Houve um erro ao tentar carregar o arquivo CSV, por favor tente novamente.',
  INVALID_FIELDS_CSV:
    'Arquivo CSV inválido, por favor, revise se todos os campos foram preenchidos corretamente.',
  UPLOAD_CSV_SUCCESSFULLY: 'Arquivo pronto para ser submetido!',
}

const FileFormatSchema = Yup.array()
  .of(
    Yup.object().shape({
      term_id: Yup.string().required(),
      card_number: Yup.string().required(),
      card_identifier: Yup.string().required(),
      unitary_value: Yup.number().required(),
    }),
  )
  .typeError(MESSAGES_FEEDBACK.INVALID_CSV)
  .required(MESSAGES_FEEDBACK.INVALID_CSV)

const FormSchema = Yup.object()
  .shape({
    timer: Yup.number()
      .min(1, MESSAGES_FEEDBACK.TIMER_MUST_BE_GREATER_THAN)
      .max(60, MESSAGES_FEEDBACK.TIMER_MUST_BE_LESS_THAN)
      .required(MESSAGES_FEEDBACK.INVALID_TIMER)
      .typeError(MESSAGES_FEEDBACK.INVALID_TIMER),
    items: FileFormatSchema,
  })
  .required(MESSAGES_FEEDBACK.REQUIRED_FIELDS)

export function TodoIncommCreate() {
  const [items, setItems] = useState(null)
  const [timer, setTimer] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const handleSubmit = async () => {
    try {
      setIsLoading(true)

      const data = {
        timer: !isNaN(timer) ? Number(timer) : null,
        items,
      }

      await FormSchema.validate(data)
      const { status } = await todoIncommService.startBulkActivation(data)

      if (status != 200) {
        message.error(MESSAGES_FEEDBACK.API_ERROR, 5)
        setIsLoading(false)
        return
      }

      message.success(MESSAGES_FEEDBACK.ACTIVATION_SUCCESSFULLY, 5)

      setTimeout(() => {
        window.location.reload()
      }, 2000)
    } catch (exception) {
      if (exception.name === 'ValidationError') {
        const messageError =
          exception.errors && exception.errors.length > 0 && exception.errors[0]

        message.error(messageError, 5)
        return
      }

      message.error(MESSAGES_FEEDBACK.ACTIVATION_FAILED, 5)
      console.error({ exception })
    } finally {
      setIsLoading(false)
    }
  }

  const onChangeFile = async data => {
    const file = data.target.files[0]

    if (!file) {
      message.error(MESSAGES_FEEDBACK.INVALID_UPLOAD_CSV, 5)
      setItems(null)
    }

    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      transform: value => value.trim(),
      error: function(error, fileName) {
        setItems(null)

        message.error(MESSAGES_FEEDBACK.INVALID_FIELDS_CSV, 5)
        console.error({ error, fileName })
      },
      complete: async function(results) {
        try {
          const fileCasted = await FileFormatSchema.cast(results.data)
          const schemaItemsValidated = await FileFormatSchema.validate(
            fileCasted,
          )

          setItems(schemaItemsValidated)

          message.success(MESSAGES_FEEDBACK.UPLOAD_CSV_SUCCESSFULLY, 5)
        } catch (exception) {
          if (exception.name === 'ValidationError') {
            const messageError =
              exception.errors &&
              exception.errors.length > 0 &&
              exception.errors[0]

            message.error(messageError, 5)
            return
          }
          setItems(null)
          message.error(MESSAGES_FEEDBACK.INVALID_CSV, 5)
          console.error({ exception })
        }
      },
    })
  }

  const handleDownloadExampleFile = event => {
    event.preventDefault()

    const csvPayload = [
      {
        term_id: 'Terminal 1',
        card_number: '010101',
        card_identifier: '000001',
        unitary_value: 10.55,
      },
    ]

    const content = Papa.unparse(csvPayload, {
      header: true,
    })

    download('file-example.csv', content, 'csv/plain')
  }

  return (
    <Card bordered={false}>
      <Form>
        <Item label='Atraso em segundos entre cada ativação *' name='timer'>
          <Input
            onChange={e => setTimer(parseInt(e.target.value))}
            min='1'
            max='60'
          />
        </Item>
        <Item label='Arquivo CSV com os cartões *' name='items'>
          <a href='#' onClick={handleDownloadExampleFile}>
            Arquivo modelo
          </a>

          <Input
            name='file'
            accept='text/csv'
            type='file'
            onChange={onChangeFile}
          />
        </Item>
        <Button loading={isLoading} onClick={handleSubmit} type='primary'>
          Iniciar ativação
        </Button>
      </Form>
    </Card>
  )
}
